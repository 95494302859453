import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~4],
		"/create": [~12],
		"/signin": [~13],
		"/ui-components": [14],
		"/ui-components/issuemapper": [15],
		"/[organizationSlug]/projects": [~5],
		"/[organizationSlug]/projects/[projectId]/(withPageLayout)/batch": [~6,[2,3]],
		"/[organizationSlug]/projects/[projectId]/(withPageLayout)/batch/[batchId]": [~7,[2,3]],
		"/[organizationSlug]/projects/[projectId]/(withPageLayout)/datasets": [~8,[2,3]],
		"/[organizationSlug]/projects/[projectId]/(withPageLayout)/datasets/[datasetId]/items": [~9,[2,3]],
		"/[organizationSlug]/projects/[projectId]/(withPageLayout)/flows": [~10,[2,3]],
		"/[organizationSlug]/projects/[projectId]/workspace": [~11,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';